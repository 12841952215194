<template>
  <div class="lesiurpackages mt-5 addbanner_area">
    <div class="container">
      <a :href="bannerHref" v-if="showBanner">
        <div class="addbanner_box">
          <img :src="bannerImage" alt="banner-image">
        </div>
      </a>

      <b-tabs class="packages_tabmenu" v-model="tabIndex">
        <b-tab class="nav-item" v-for="(dealTypeItem, index) in dealTypeList" :key="index" :active="index === 0" >
          <template #title>
            <div class="tab-title"><p>{{ dealTypeItem.name }}</p></div>
          </template>
          <b-card class="tab-pane face pt-3 bg-transparent">
            <LesiurtabContent
              :dealListItem="dealsList[index]"
              :isMore="isMoreDealData"
              :state="showMoreState"
              :active="index === tabIndex"
              @showMoreDeal="showMoreDeal"
              v-if="dealsList && dealsList[index].list.length"
            />
            <ContentLoading v-else :type="device === 'mobile' ? 'mobile-deal':'deal'" />
          </b-card>
        </b-tab>
        <!-- <b-tab class="nav-item"
          v-if="domesticTypeTab && domesticTypeTab.list.length"
          :key="(dealTypeList.length + 1)"
          :active="(dealTypeList.length + 1) === tabIndex"
        >
          <template #title>
            <div class="tab-title"><p>{{ $t('home.domestic-tourism') }}</p></div>
          </template>
          <b-card class="tab-pane face pt-3">
            <LesiurtabContent
              v-if="domesticTypeTab && domesticTypeTab.list.length"
              :dealListItem="domesticTypeTab"
              :active="dealTypeList.length + 1 === tabIndex"
            />
            <ContentLoading v-else :type="device === 'mobile' ? 'mobile-deal':'deal'" />
          </b-card>
        </b-tab> -->
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab, BCard } from 'bootstrap-vue';
import TemplateLesiurtabs from './TemplateLesiurtabs';

export default {
  name: 'LesiurtabsTheme1',
  mixins: [TemplateLesiurtabs],
  components: {
    LesiurtabContent: () => import('@/components/home/lesiurtabContent/LesiurtabContentTheme1'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    BTabs,
    BTab,
    BCard,
  },
  mounted() {
    // console.log(this.dealsList, this.domesticTypeTab);
  },
};
</script>
<style>
.lesiurpackages ul.nav.nav-tabs {
  margin-top: 15px;
  margin-bottom: 15px;
}
.lesiurpackages ul.nav.nav-tabs li:first-child {
  border-right: none;
}
.lesiurpackages ul.nav.nav-tabs li {
  padding: 0 0;
  margin: 0 0;
  position: relative;
  padding-left: 10px;
  text-transform: capitalize;
}
.lesiurpackages .tab-content {
  border: none;
}
.lesiurtabFilter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card .card-body img {
  max-width: 100%;
}

.addbanner_box img{
  width: 100%;
  max-width: 1536px;
  max-height: 380px;
  border-radius: 10px;
}

.addbanner_area .packages_area {padding: 60px 0px 60px}
.addbanner_area .packages_area .packages_tabmenu{ margin-bottom:24px; }

.addbanner_area .packages_tabmenu .nav-tabs .nav-link {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    padding: 12px 8px;
    border: none;
    position: relative;
}
.addbanner_area .packages_tabmenu .nav-tabs a.nav-link:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #1993C8;
    box-shadow: 0px 3px 6px #1993C8;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -1px;
    opacity: 0;
}
.addbanner_area .packages_tabmenu .nav-tabs a.active.nav-link:after {
    opacity: 1 !important;
}
.addbanner_area .packages_tabmenu .nav-tabs .nav-item.show .nav-link,
.addbanner_area .packages_tabmenu .nav-tabs .nav-link.active {
    color: #1993C8;
    background-color: transparent;
    border-color: transparent;
}
.packages_tabmenu .nav-tabs .nav-link.active:after {
    opacity: 1;
}

.bg-transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}

@media (max-width: 479px) {
  .lesiurpackages_head h1 {
    font-size: 18px;
  }
  .lesiurpackages_head {
    min-height: auto;
    padding: 20px 20px;
  }
  .lesiurpackages ul.nav.nav-tabs li a {
    padding: 6px 8px;
    font-size: 14px;
    margin-right: 5px;
  }
  .lesiurpackages ul.nav.nav-tabs {
    padding-right: 0px;
  }
  .lesiurpackages_hotelsbox.big {
    height: 300px;
  }
  .lesiurpackages ul.nav.nav-tabs li {
    padding-left: 5px;
    max-width: 20rem;
    white-space: nowrap;
  }
  /* .tab-title{
    width: min-content;
  } */
  .lesiurpackages ul.nav.nav-tabs li a.active:after{
    display: none;
  }
  .tab-title {
    width: fit-content;
  }
}
</style>
<style scoped>
.card .card-body {
  padding: 0px;
}
</style>
